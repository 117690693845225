<template>
  <v-dialog :value="dialog" max-width="500">
    <v-card class="modal-alert">
      <v-card-text class="modal-content">
        <div class="box-icon">
          <font-awesome-icon icon="vector-square" />
        </div>

        <h3>{{ $t('unsed_area.title') }}</h3>

        <article>
          {{ $t('unsed_area.message') }}
        </article>
      </v-card-text>

      <v-card-actions class="modal-footer">
        <v-btn @click="close">{{ $t('cancelar') }}</v-btn>
        <v-btn class="submit" color="#39AF49" @click="openConfigArea">
          {{ $t('unsed_area.action') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <modal-config-area
      v-if="dialogConfigArea"
      :dialog="dialogConfigArea"
      @close="closeModalConfigArea"
    />
  </v-dialog>
</template>

<script>
import ModalConfigArea from '@/modules/menu/components/modals/ModalConfigArea.vue'

export default {
  name: 'ModalUnusedArea',

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    ModalConfigArea,
  },

  data() {
    return {
      dialogConfigArea: false,
    }
  },

  methods: {
    openConfigArea() {
      this.dialogConfigArea = true
    },

    closeModalConfigArea() {
      this.dialogConfigArea = false
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.modal-alert .modal-content {
  padding: 32px !important;
}

.modal-alert .modal-content h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1a2b46;
  text-align: center;
  margin-top: 16px;
}
.modal-alert .modal-content article {
  font-size: 16px;
  font-weight: 400;
  color: #5b6459;
  text-align: center;
  margin-top: 8px;
}

.box-icon {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #d8f2dc;
  border-radius: 100%;
}

.box-icon svg {
  color: #39af49;
}

.modal-footer {
  padding: 16px 24px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e6e9e6;
}
.modal-footer button {
  box-shadow: none;
  height: 40px !important;

  border: 1px solid #e6e9e6;
  padding: 16px !important;

  text-transform: capitalize;
  font-size: 14px;
  color: #1a2b46;
  font-weight: 500;
}

.modal-footer button.submit {
  color: #fff;
}
</style>
